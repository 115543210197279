<template>
  <v-card class="card-shadow border-radius-xl">
    <div class="px-4 py-4">
      <full-calendar
        :options="calendarOptions"
        ref="fullCalendar"
      ></full-calendar>
    </div>
  </v-card>
</template>
<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        contentHeight: "auto",
        initialView: "dayGridMonth",
        selectable: true,
        initialDate: new Date(),
        editable: true,
        locale: 'de',
        headerToolbar: {
          start: "title", // will normally be on the left. if RTL, will be on the right
          center: "",
          end: "today prev,next", // will normally be on the right. if RTL, will be on the left
        },
        events: []
      },
    };
  },
  mounted() {
    this.$store.dispatch("getEventsAdmin");
    this.setCalendarEvents()
  },
  computed: {
    events() {
      return this.$store.getters.events;
    },
  },
  methods: {
    setCalendarEvents(){
      
      if(this.events){
        this.events.forEach(element => {
          var entry = {
            title: element.title,
            start: element.date,
            end: element.date,
            className: "bg-gradient-info",
          }
          this.calendarOptions.events.push(entry)
        });
      }

    }
  }
};
</script>
